import React from 'react';
import { AnecdotesFeed } from './AnecdotesFeed';
import './App.css';

function App() {
  return (
    <div className="App">
      <AnecdotesFeed /> 
      <footer className="app-footer mt-auto">
        <p>&copy; Amazon 2024</p>
      </footer>
    </div>
  );
}

export default App;
