/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:6ad9369a-b623-4342-842e-26287b425b8b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Qs4e3m5P7",
    "aws_user_pools_web_client_id": "3a43s36pn7i7c35pi0mkfgmup5",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "percept311647de5fde4a9499d90c8fc6c57a69fab05-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
