import React, { useState, useRef, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import xLogoImage from './img/X-logo.png';
import './AudioRecorder.css';
const AudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [duration, setDuration] = useState(0);
  const [timerId, setTimerId] = useState(null);
  const [barHeights, setBarHeights] = useState([10, 20, 15, 25, 10]);
  const [barsTimerId, setBarsTimerId] = useState(null);

  const generateRandomFileName = (extension = 'wav') => {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 10000);
    return `file_${timestamp}_${randomNum}.${extension}`;
  };

  const handleRecordToggle = () => {
    if (isRecording) {
      clearInterval(timerId);
      setTimerId(null);
      setBarsTimerId(null);
      setDuration(0);
    } else {
      const id = setInterval(() => {
        setDuration(prev => prev + 1);
      }, 1000);
      setTimerId(id);

      const barsId = setInterval(() => {
        setBarHeights([
          getRandomHeight(),
          getRandomHeight(),
          getRandomHeight(),
          getRandomHeight(),
          getRandomHeight(),
        ]);
      }, 300); // Update every 300ms
      setBarsTimerId(barsId);
    }
    setIsRecording(!isRecording);
  };

  const getRandomHeight = () => Math.floor(Math.random() * 20) + 10;

  const startRecording = async () => {
    handleRecordToggle()
    setIsUploaded(false)
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);

    mediaRecorderRef.current = mediaRecorder;

    const chunks = [];
    mediaRecorder.ondataavailable = (event) => {
      chunks.push(event.data);
    };

    mediaRecorder.onstop = async () => {
      const blob = new Blob(chunks, { type: 'audio/wav' });
      const url = URL.createObjectURL(blob);
      setIsUploading(true)
      try {
        const file = new File([blob], generateRandomFileName(), { type: 'audio/wav' });
        const result = await Storage.put(file.name, file, {
          contentType: file.type,
          level: 'public' 
        });
        console.log('Audio uploaded successfully:', result);
        setIsUploaded(true); // Set isUploaded to true after uploading
      } catch (error) {
        console.error('Error uploading audio:', error);
      }
      setIsUploading(false)
      };

      mediaRecorder.start();
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      handleRecordToggle()
    }
  };

  const formatDuration = (duration) => {
    const minutes = String(Math.floor(duration / 60)).padStart(2, '0');
    const seconds = String(duration % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    return () => {
      clearInterval(timerId);
      clearInterval(barsTimerId);
    }
  }, [timerId, barsTimerId]);

  return (
    <div className="record-container">
      {
      isUploaded ? <div>Thank you!</div> : 
      <div className="record-content">
        {
          isUploading ? <div>Uploading...</div> :
          <div className="record-section">
            <p>Audio</p>
            {isRecording ? (
              <div>
                <div style={styles.timer}>{formatDuration(duration)}</div>
                <div style={{ ...styles.buttonBase, ...styles.stopButton }} onClick={stopRecording}>
                  Stop
                </div>
              </div>
            ) : (
              <div style={{ ...styles.buttonBase, ...styles.recordButton }} onClick={startRecording}>
                Record
              </div>
            )}

            {isRecording && (
              <div style={styles.audioBars}>
                {barHeights.map((height, index) => (
                  <div key={index} style={{ ...styles.bar, height: `${height}px` }}></div>
                ))}
              </div>
            )}
          </div>
        }
      </div>
      }
      <p>or</p>
      <div className="tweet-section">
        <p className='text-center'>Tweet with <span className="gradient-text">#SFF2024</span></p>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <img src={xLogoImage} alt="X-logo" className="h-6" />
        </a>
      </div>
    </div>
  );
};


const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  buttonBase: {
    width: '70px',
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '16px',
    border: '3px solid lightgrey', // Light grey circular border
    borderRadius: '50%',           // Circular border
    boxSizing: 'border-box',
  },
  recordButton: {
    backgroundColor: 'red',
  },
  stopButton: {
    width: '60px',                  // Reduced size inside grey border
    height: '60px',
    backgroundColor: 'red',
    borderRadius: '15%',           // Slightly rounded rectangle
  },
  timer: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '10px',
    color: '#333',
  },
  audioBars: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: '20px',
    width: '60px',
  },
  bar: {
    width: '5px',
    height: '15px',
    backgroundColor: 'grey',
    animation: 'bounce 0.5s infinite alternate',
  },
};


export default AudioRecorder;

